import React from "react"
import { Spinner } from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function IndexPage() {
  return (
    <Layout noStyles>
      <SEO />

      <div className="fullscreen-centered">
        <Spinner size="lg" color="primary" />
      </div>
    </Layout>
  )
}
